import * as React from "react"
import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { navigate } from "gatsby"
import useAuthenticationCheck from "../hooks/useAuthenticationCheck"
import styled from "styled-components"
import Login from "../components/header/login"
import Signup from "../components/header/signup"
import colors from "../styles/colors"
import { applyTextStyle } from "../styles/typography"
import ScoresContainerMock from "../components/ScoresContainerMock"
import PageLoader from "../components/PageLoader"

const IndexPage: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0()
  const [error, setError] = useState<string | null>(null)
  const [showResend, setShowResend] = useState(false)
  const [email, setEmail] = useState("")
  const [statusMessage, setStatusMessage] = useState<string | null>(null)

  useAuthenticationCheck()

  // Navigate to the dashboard if the user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard")
    } else if (typeof window !== "undefined") {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const errorDescription = urlParams.get("error_description")

      if (errorDescription) {
        setError(decodeURIComponent(errorDescription))
        window.history.replaceState(null, "", window.location.pathname)
      }
    }
  }, [isAuthenticated])

  const handleResendClick = async () => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/user/verify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      )

      if (response.ok) {
        setError(null)
        setShowResend(false)
        setStatusMessage("Email verification has been sent!")
      } else {
        setShowResend(false)
        setError("Something went wrong, please try again later")
      }
    } catch (error) {
      setShowResend(false)
      setError("Something went wrong, please try again later")
    }
  }

  if (isLoading) {
    return <PageLoader />
  }
  return (
    <>
      <Heading>
        <Header>Deploy AI Assistants with confidence</Header>
        <SubHeading>
          <SubHeader>
            Codaco adds a layer of customer experience and risk mitigation to
            your chatbots and voice applications
          </SubHeader>

          <CallToAction>
            {error && (
              <ResendMailWrapper>
                <Error>{error}</Error>
                {showResend && error.toLowerCase().includes("email") && (
                  <EmailInputWrapper>
                    <EmailInput
                      type="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Enter your email"
                    />
                    <EmailSendButton onClick={handleResendClick}>
                      Resend
                    </EmailSendButton>
                  </EmailInputWrapper>
                )}
                {!showResend && error.toLowerCase().includes("email") && (
                  <LostMailButton onClick={() => setShowResend(true)}>
                    Lost the email?
                  </LostMailButton>
                )}
              </ResendMailWrapper>
            )}
            {statusMessage && <div>{statusMessage}</div>}
            <CallToActionButtons>
              <Login />
              <Signup />
            </CallToActionButtons>
          </CallToAction>
        </SubHeading>
      </Heading>
      <ScoresContainerMock />
    </>
  )
}

const CallToAction = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 80px;
`
const CallToActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 80px;
`

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
`

const Heading = styled.div`
  max-width: 1024px;
  margin: 80px auto 20px auto;
  display: flex;
  flex-direction: column;
`
const Header = styled.h1`
  font-size: 68px;
  color: #333333;
`
const SubHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const SubHeader = styled.p`
  width: 420px;
  font-size: 24px;
  color: #222222;
`

const Card = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 50px 20px 50px 20px;
  margin: 20px;
  margin-top: -48px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  place-items: center;
  flex-direction: column;
  width: 500px;
`

const Img = styled.img`
  width: 100px;
  margin-bottom: 20px;
`

const SubTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`

const ResendMailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`

const Error = styled.div`
  color: red;
  text-align: center;
`

const LostMailButton = styled.button`
  background-color: white;
  border: none;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
`
const EmailInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const EmailInput = styled.input`
  display: block;
  box-sizing: border-box;
  background-color: ${colors.neutralWhite};
  outline: 0;
  border: 1px solid ${colors.grey1};
  color: ${colors.grey2};
  appearance: none;
  border-radius: 4px;
  ${applyTextStyle("label1")};
  padding: 8px 12px 7px 12px;
`

const EmailSendButton = styled.button`
  background-color: ${colors.green2};
  color: white;
  height: 32px;
  font-size: 15px;
  font-weight: 600;
  width: 100px;
  border-radius: 5px;
  border: 1px solid whitesmoke;
  cursor: pointer;
`

export default IndexPage
