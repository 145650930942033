import React, { useEffect, useState } from "react"
import ScoreProgressBar from "./ScoreProgressBar"
import styled from "styled-components"
import { applyTextStyle } from "../styles/typography"
import colors from "../styles/colors"

const ScoresContainerMock = () => {
  const [score, setScore] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      if (score === 100) {
        return
      }
      setScore(score + 1)
    }, 10)
  }, [score])

  return (
    <Wrapper>
      <ScoreContainer>
        <div>
          <Score>{score || 0}</Score>
          <OverallText>Overall score</OverallText>
        </div>
        <ScoreRight>
          {suggestions?.scores?.scoresArray?.map(scoreItem => (
            <ScoreProgressBar
              key={scoreItem.name}
              title={scoreItem.name}
              defaultColor={scoreItem.color} // Use hex string directly
              fillColor={scoreItem.color} // Use hex string directly
              progress={score}
            />
          ))}
        </ScoreRight>
      </ScoreContainer>
    </Wrapper>
  )
}
const suggestions = {
  text: "",
  messages: [],
  scores: {
    overallScore: 100,
    scoresArray: [
      { id: 25, name: "Fundamentals", color: "#005AA0", score: 100 },
      { id: 31, name: "Human-centricity", color: "#1AA298", score: 100 },
      { id: 26, name: "Ethics", color: "#AD52BB", score: 100 },
      { id: 30, name: "Simplicity", color: "#FFA500", score: 100 },
      { id: 32, name: "Style", color: "#babc52", score: 100 },
      { id: 59, name: "Compliance", color: "#8cbc52", score: 100 },
    ],
  },
}

const Wrapper = styled.div`
  width: 1024px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
`

const ScoreContainer = styled.div`
  display: flex;
  margin-top: 48px;
  flex-direction: column;
  justify-content: space-between;
`

const Score = styled.div`
  ${applyTextStyle("heading1")};
  color: ${colors.grey6};
`

const OverallText = styled.div`
  ${applyTextStyle("label7")};
  color: ${colors.grey6};
  margin-bottom: 4px;
`

const ScoreRight = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 282px;
  margin-top: 48px;
  gap: 20px;
`

export default ScoresContainerMock
