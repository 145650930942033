import React from "react"
import logo from "../assets/codaco.png"
import styled from "styled-components"

const PageLoader = () => {
  return (
    <Wrapper>
      <Loader>
        <Img src={logo} alt="Codaco logo" />
        <div>Loading...</div>
      </Loader>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Loader = styled.div`
  display: flex;
  flex-direction: column;
`

const Img = styled.img`
  max-height: 40px;
  margin-top: 25px;
`
export default PageLoader
